<template>
    <v-dialog v-if="monit !== null" v-model="exibeFormularioMonit" width="600" persistent>
      <v-form ref="formulario">
        <v-card>
          <v-card-title>
            <h2 class="grey--text">{{ isEditMode ? 'Atualizar Monit' : 'Criar Monit' }}</h2>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="9">
                  <v-text-field
                    label="Nome"
                    color="green"
                    required
                    v-model="monit.name"
                    :rules="nameValidation"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-card class="mx-5" flat>
                    <v-switch label="Ativar" color="green" v-model="monit.active">
                    </v-switch>
                  </v-card>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-combobox
                    label="E-MAIL"
                    color="green"
                    v-model="monit.email"
                    clearable
                    multiple
                    :rules="emailValidation"
                    required
                    hint="Pressione ENTER para adicionar um ou mais e-mails para serem notificados"
                    persistent-hint
                  >
                    <template v-slot:selection="{ item, select }">
                      <v-chip close @click="select" @click:close="removeEmail(item)">
                        <span>{{ item }}</span>
                      </v-chip>
                    </template>
                  </v-combobox>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-card class="d-flex flex-row-reverse" flat>
                    <v-col cols="4">
                      <v-btn
                        color="green"
                        outlined
                        block
                        @click="validarFormulario()"
                      >
                        {{ isEditMode ? 'Atualizar' : 'Criar' }}
                      </v-btn>
                    </v-col>
                    <v-col cols="4">
                      <v-btn color="red" outlined block @click="cancelar">
                        CANCELAR
                      </v-btn>
                    </v-col>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-form>
    </v-dialog>
  </template>
  
  <script>
  export default {
    props: {
      exibeFormularioMonit: Boolean,
      monit: {},
    },
    computed: {
      isEditMode() {
        return this.monit && this.monit._id != null;
      }
    },
    data() {
      return {
        nameValidation: [
          v => !!v || 'Nome é obrigatório',
          v => (v && v.length >= 3) || 'O nome deve ter pelo menos 3 caracteres'
        ],
        emailValidation: [
          v => !!v || 'E-mail é obrigatório',
          v => (v || []).every(email => /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)) || 'E-mail inválido'
        ]
      };
    },
    methods: {
      validarFormulario() {
        const form = this.$refs.formulario;
        if (form && form.validate()) {
          this.salvarMonit();
        }
      },

      salvarMonit() {
        this.$emit("salvar-monit");
      },
      cancelar() {
        this.$emit("cancelar");
      },
      removeEmail(item) {
        this.monit.email = this.monit.email.filter(email => email !== item);
      },
    },
  };
  </script>
  