<template>
    <nav>
        <v-toolbar short dark>
            <v-avatar>
                <img src="../components/robo.png" alt="robo">
            </v-avatar>
            <v-toolbar-title class="text-uppercase white--text">
                <span class="pl-4 ml-4">Monit</span>      
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn outlined @click="abrirFormulario">
                <v-icon color="white">mdi-plus-box</v-icon>
                <p class="pa-1 ma-1">Criar Monit</p>
            </v-btn>
        </v-toolbar>

        <CriarMonit 
            :exibeFormularioMonit="exibeFormularioMonit"
            :monit="monit"
            @salvar-monit="inserirMonit"
            @cancelar="cancelar"
        />
    </nav>
</template>

<script>
import CriarMonit from './CriarMonit.vue';
import barraController from './barraController.js';

export default {
    components: { CriarMonit },
    mixins: [barraController]
};
</script>
